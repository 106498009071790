import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const Game = lazy(() => import('./pages/Game'));
const Controller = lazy(() => import('./pages/Controller'));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/">
            <Game />
          </Route>
          <Route path="/controller/:controllerId">
            <Controller />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
